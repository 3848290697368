/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const head = req.clone({
      setHeaders: {
        ['Content-Type']: 'application/json',
        ['Accept']: 'application/json, text/plain, */*',
      },
      responseType: 'json',
    });

    const accessToken = this.cookieService.get('accessToken');

    if (!accessToken) {
      return next.handle(head); /*.pipe(
        catchError((e: HttpErrorResponse) => {
          if (
            req.url.includes('notifications') ||
            req.url.includes('login') ||
            req.url.includes('testimonials') ||
            req.url.includes('Clients?ruc') ||
            req.url.includes('/registration-request/create') ||
            req.url === environment.appVersionUrl
          ) {
            return throwError(() => e);
          }
          this.errorManager.getErrorResponse(e);
          return throwError(() => e);
        }),
      );*/
    }

    const headers = req.clone({
      setHeaders: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json, text/plain, */*',
      },
      responseType: 'json',
    });

    return next.handle(headers); /*.pipe(
      catchError((e: HttpErrorResponse) => {
        if (
          (req.url.includes('coupons') && req.method === 'PUT') ||
          req.url.includes('client-devices')
        ) {
          return throwError(() => e);
        }
        this.errorManager.getErrorResponse(e);
        return throwError(() => e);
      }),
    );*/
  }
}
