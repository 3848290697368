/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { SessionCookies } from '@enums';
import { AppRoutes } from '@constants';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private router: Router,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const accessToken = this.cookieService.get('accessToken');

    const headersConfig: { [name: string]: string } = {
      Accept: 'application/json, text/plain, */*',
    };

    if (accessToken) {
      headersConfig['Authorization'] = `Bearer ${accessToken}`;
    }

    if (!(req.body instanceof FormData)) {
      headersConfig['Content-Type'] = 'application/json';
    }

    const clonedRequest = req.clone({
      setHeaders: headersConfig,
      responseType: 'json',
    });

    return next.handle(clonedRequest).pipe(
      catchError((e: HttpErrorResponse) => {
        if (
          e.status === HttpStatusCode.Unauthorized &&
          !req.url.includes('change-password')
        ) {
          this.cookieService.delete(SessionCookies.accessToken);
          this.cookieService.delete(SessionCookies.userName);
          this.cookieService.delete(SessionCookies.needsPasswordChange);
          this.router.navigate([`/${AppRoutes.LOGIN}`]);
        }
        return throwError(() => e);
      }),
    );
  }
}
