import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutes } from '@constants';

const routes: Routes = [
  {
    path: AppRoutes.DEFAULT_EMPTY,
    redirectTo: AppRoutes.LOGIN,
    pathMatch: 'full',
  },
  {
    path: AppRoutes.DEFAULT_EMPTY,
    loadChildren: () =>
      import('./features/home/home.module').then(m => m.HomeModule),
  },
  {
    path: AppRoutes.SELLER_PORTAL,
    loadChildren: () =>
      import('./features/seller-portal/seller-portal.module').then(
        m => m.SellerPortalModule,
      ),
  },
  { path: '**', redirectTo: AppRoutes.LOGIN },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
