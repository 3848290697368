import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppRoutes } from '@constants';
import { FormStatus } from 'src/app/core/data/enums/form.enum';

@Component({
  selector: 'digicem-create-new-password',
  templateUrl: './create-new-password.component.html',
})
export class CreateNewPasswordComponent implements OnInit {
  protected token: string = '';
  protected statusForm: FormStatus = FormStatus.INITIAL;
  protected formSubmitted = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.subscribeTokenParameter();
  }

  private subscribeTokenParameter(): void {
    const TOKEN = 'token';
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params[TOKEN]) {
        this.token = params[TOKEN];
      }
    });
  }

  protected handleOnChangeStatus(status: FormStatus): void {
    this.statusForm = status;
    switch (status) {
      case FormStatus.FINISHED:
        this.router.navigate([AppRoutes.LOGIN]);
        break;
      case FormStatus.SENT:
        this.formSubmitted = true;
        break;
    }
  }
}
