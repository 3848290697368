import { Component, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleAnalyticsService } from './services/google-analytics.service';

@Component({
  selector: 'digicem-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy {
  private pageStartTime: number = 0;
  private pagePath: string = '';

  dateWrapper = {
    now: () => Date.now(),
  };

  constructor(
    private router: Router,
    private gaService: GoogleAnalyticsService,
  ) {
    this.trackPageViews();
  }

  private trackPageViews() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Enviar tiempo de la página anterior
        if (this.pagePath) {
          const timeSpent = this.dateWrapper.now() - this.pageStartTime;
          this.gaService.trackEvent('time_spent', {
            page_path: this.pagePath,
            duration: timeSpent / 1000,
          });
        }

        // Actualizar nueva página y tiempo de inicio
        this.pagePath = event.urlAfterRedirects;
        this.pageStartTime = this.dateWrapper.now();

        // Registrar vista de página
        this.gaService.trackEvent('page_view', { page_path: this.pagePath });
      }
    });
  }

  ngOnDestroy() {
    // Si el usuario cierra la página, enviar evento con el tiempo final
    const timeSpent = this.dateWrapper.now() - this.pageStartTime;
    this.gaService.trackEvent('time_spent', {
      page_path: this.pagePath,
      duration: timeSpent / 1000,
    });
  }
}
