<div
  class="home-component min-h-screen lg:h-screen overflow-auto lg:overflow-hidden"
>
  <div class="portal-content bg-neutrals-300 min-h-screen lg:h-full">
    <div
      class="container flex mx-auto min-h-screen lg:h-full justify-center flex-col lg:flex-row"
    >
      <div
        class="flex flex-col items-center justify-center w-full lg:w-1/2 p-4"
      >
        <digicem-password-form
          [token]="token"
          (onChangeStatusForm)="handleOnChangeStatus($event)"
        >
        </digicem-password-form>

        <div class="flex flex-col items-end my-8" *ngIf="!formSubmitted">
          <ui-logotype
            width="178"
            m-width="170"
            preffix-fill="primary-600"
            suffix-fill="secondary-400"
          ></ui-logotype>
        </div>
      </div>
    </div>
  </div>
</div>
